.icon-stack {
  position: relative;
  line-height: 4em;
}
.icon-stack-1x,
.icon-stack-3x {
  position: absolute;
  left: 0;
  width: 100%;
  display: block !important;
  font-size: 18px !important;
}
.superpuestIcon {
  margin-top: 13px;
  height: 30px;
}
.icon-stack-1x {
  line-height: inherit;
  margin-left: -7px;
  margin-top: -32px;
}
.far,
.fa {
  position: relative;
  display: table-cell;
  width: 60px;
  height: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 20px;
}
.main-menu,
nav.main-menu {
  width: 250px;
}
@media (min-width: 700px) {
  .on-top-button .menu-item i {
    display: none !important;
  }
  .menu-item:hover > a,
  nav.menu-item.active > a,
  .dropdown-menu > li > a:hover,
  .dropdown-menu > li > a:focus,
  .dropdown-menu > .active > a,
  .dropdown-menu > .active > a:hover,
  .dropdown-menu > .active > a:focus,
  .no-touch .dashboard-page nav.dashboard-menu ul li:hover a,
  .dashboard-page nav.dashboard-menu ul li.active a {
    color: #1788c5;
    background-color: #ffffff;
    border-radius: 40px;
    cursor: pointer;
  }
}
@media (max-height: 500px) {
  .main-menu .nav-text {
    background-color: #f0f6f9;
  }
  .menu-item {
    background-color: #f0f6f9;
  }
  .far,
  .fa {
    background-color: #f0f6f9;
  }
  .counter {
    display: none;
  }
}
@media (max-width: 700px) {
  .main-menu,
  nav.main-menu {
    width: 55px;
  }
  .nav-text-title {
    display: none;
  }
  .menu-item {
    padding-left: 10px;
    padding-right: 20px;
  }
}
@media (min-width: 700px) {
  .menu-item {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.main-menu {
  background: #f0f6f9;
  border-right: 1px solid #e5e5e5;
  bottom: 0;
  height: 100%;
  left: 0;
  width: 60px;
  overflow: hidden;
  -webkit-transition: width 0.05s linear;
  transition: width 0.05s linear;
  -webkit-transform: translateZ(0) scale(1, 1);
  z-index: 1000;
  position: fixed;
}
.main-menu > ul {
  margin: 7px 0;
}
.menu-item {
  position: relative;
  display: block;
  width: 250px;
}
.menu-item > a {
  position: relative;
  display: table;
  border-spacing: 0;
  color: #1788c5;
  font-family: arial;
  font-size: 18px;
  font-weight: 350;
  font-size: 18px;
  line-height: 22px;
  text-decoration: none;
  -webkit-transform: translateZ(0) scale(1, 1);
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
  height: 40px;
  bottom: 0;
}
.main-menu .nav-icon {
  position: relative;
  display: table-cell;
  width: 60px;
  height: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
}
.main-menu .nav-text {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 250px;
  text-align: left;
  padding-left: 35px;
  font-style: normal;
  font-weight: 350;
  font-size: 18px;
  line-height: 22px;
}
.no-touch .scrollable.hover {
  overflow-y: hidden;
}
.no-touch .scrollable.hover:hover {
  overflow-y: auto;
  overflow: visible;
}
a:hover,
a:focus {
  text-decoration: none;
}
nav {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
nav ul,
nav li {
  outline: 0;
  margin: 0;
  padding: 0;
  margin-right: -50px;
  margin-bottom: 16px;
}

.title-menu {
  padding-left: 10px;
  padding-top: 20px;
}
.title-menu span {
  color: #999;
}
.activeMenu {
  background-color: #ffffff;
  border-radius: 40px;
}
.activeMenu span {
  color: #1788c5;
}
.activeMenu i {
  color: #1788c5;
}
.footer-button {
  position: absolute;
  bottom: 0px;
  background-color: #f0f6f9;
}

.footer-button > .menu-item > a > .nav-text {
  padding-left: 10px !important;
}

.menu-icon {
  padding-left: 20px;
}

.counter-container {
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #1788c5;
  margin: 7px;
  text-align: center;
  width: 25px;
  height: 25px;
}

.counter {
  color: #1788c5;
  font-size: 16px;
}
